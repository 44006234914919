<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />
						</h2>
						<div v-if="user_role == 'assistant'">
							<h2 class="content-header-title float-left pr-1 mb-0">
								{{ role }}
							</h2>
						</div>
						<div v-else>
							<h2 class="content-header-title float-left pr-1 mb-0">
								{{ user_role.charAt(0).toUpperCase() + user_role.slice(1) }}
							</h2>
						</div>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> View </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div>
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="d-flex justify-content-center flex-column col-xl-6 col-21">
							<div class="d-flex justify-content-start">
								<span class="b-avatar badge-light-primary rounded" style="width: 104px; height: 104px"
									><span class="b-avatar-img">
										<b-img
											:src="require('@/assets/images/svg/hatchery/farm.svg')"
											class="svg-img"
											height="30px"
											width="30px"
										/>
									</span>
								</span>
								<div class="d-flex flex-column justify-content-center ml-1">
									<div class="mb-1">
										<div v-if="user_role == 'assistant'">
											<h2 class="content-header pr-1 mb-0">
												{{ role }}
											</h2>
										</div>
										<div v-else>
											<h2 class="content-header pr-1 mb-0">
												{{ user_role.charAt(0).toUpperCase() + user_role.slice(1) }}
											</h2>
										</div>
									</div>
									<div class="mb-1">
										<h4 class="mb-0">{{ user.name }}</h4>
									</div>
									<div class="mb-1">
										<h6 class="mb-0 d-flex">
											<div v-if="user.status === 'Active'" class="activeClass">
												<b-badge pill variant="success" class="badge-glow">{{ user.status }} </b-badge>
											</div>
											<div v-else class="inactiveClass">
												<b-badge pill variant="danger" class="badge-glow">
													{{ user.status }}
												</b-badge>
											</div>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-12">
							<table class="mt-2 mt-xl-0 w-100">
								<tr>
									<th class="pb-50">
										<span class="font-weight-bold">User Name:</span>
									</th>
									<td class="pb-50 text-capitalize">
										{{ user.user_name }}
									</td>
								</tr>
								<tr>
									<th class="pb-50">
										<span class="font-weight-bold">Email:</span>
									</th>
									<td class="pb-50">{{ user.email }}</td>
								</tr>
								<tr>
									<th class="pb-50">
										<span class="font-weight-bold">Phone:</span>
									</th>
									<td class="pb-50">{{ user.phone_no }}</td>
								</tr>
								<tr>
									<th class="pb-50">
										<span class="font-weight-bold">Address:</span>
									</th>
									<td class="pb-50 text-capitalize">{{ user.address }}</td>
								</tr>
								<tr>
									<th class="pb-50">
										<span class="font-weight-bold">Country:</span>
									</th>
									<td class="pb-50 text-capitalize">
										{{ user.country.name }}
									</td>
								</tr>
								<tr>
									<th class="pb-50">
										<span class="font-weight-bold">State:</span>
									</th>
									<td class="pb-50">{{ user.state.name }}</td>
								</tr>
								<tr>
									<th class="pb-50">
										<span class="font-weight-bold">City:</span>
									</th>
									<td class="pb-50 text-capitalize">
										{{ user.city.name }}
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Logo from "@core/layouts/components/Logo.vue";
import * as Vue from "vue";
import axiosIns from "@/libs/axios";
import VueAxios from "vue-axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},
	props: {
		permissionName: {
			type: String,
		},
	},
	data() {
		return {
			stateList: [],
			countryList: [],
			state_name: null,
			cityList: [],
			user: [],
			url: null,
			formData: {
				user_image: null,
				name: null,
				email: null,
				user_name: null,
				password: null,
				status: null,
				phone_no: null,
				address: null,
				selectedCountry: null,
				selectedCity: null,
			},
			user_role: window.location.pathname.split("/")[2],
			role: "Assistant Manager",
		};
	},
	created: function () {
		this.getUserId();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getUserId() {
			const userId = this.$route.params.userId;
			axiosIns
				.get(`web/users/${userId}`)
				.then((response) => {
					this.user = response.data.user;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.activeClass {
	background-color: transparent !important;
}
.top-header {
	border-bottom: 1px solid #296db4;
	padding: 1rem 0rem;
}
.form-item-section {
	background-color: $product-details-bg;
}

.form-item-action-col {
	width: 27px;
}

.repeater-form {
	transition: 0.35s height;
}

.v-select {
	&.item-selector-title,
	&.payment-selector {
		background-color: #fff;

		.dark-layout & {
			background-color: unset;
		}
	}
}

.dark-layout {
	.form-item-section {
		background-color: $theme-dark-body-bg;

		.row .border {
			background-color: $theme-dark-card-bg;
		}
	}
}
</style>
